import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  disableBtn: false,
};

export const editableTableSlice = createSlice({
  name: "editable-table",
  initialState,
  reducers: {
    disableAddBtn: (state, action) => {
      state.disableBtn = action.payload;
    },
  },
});

export const { disableAddBtn } = editableTableSlice.actions;

export default editableTableSlice.reducer;
