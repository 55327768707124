import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // ServiceProviderId: '',
  // FundId: '',
  // PropertyId: '',
  // DatasetOrReportPackageId:'',
  // IsForProperty:0,
  // IsForDataset:0,

  mappingFileParameters: {
    ServiceProviderId: "",
    FundId: "",
    PropertyId: "",
    DatasetOrReportPackageId: "",
    IsForProperty: 0,
    IsForDataset: 0,
  },
  mappingFileParam: [],
  viewMappingflag: false,
  isFundEnabled: false,
};

const handleMappingData = (state, data) => {
  state.mappingFileParam.push(data);
};

export const DatasetMappingSlice = createSlice({
  name: "datasetMapping",
  initialState,
  reducers: {
    // getDataset: (state, action) => {
    //   state.dataset = action.payload;
    // },
    // getReportPackageDataset: (state, action) => {
    //   state.reportPackageDataset = action.payload;
    // },
    // addReviewerToTable: (state, action) => {
    //   validateValues(state, action.payload);
    // },
    // addReviewer: (state, action) => {
    //   state.addReviewer.OrderNo = action.payload.OrderNo;
    //   state.addReviewer.UserName = action.payload.UserName;
    //   state.addReviewer.ApproverId = action.payload.ApproverId;
    // },
    ///ServiceProviderId/:FundId/:PropertyId/:DatasetOrReportPackageId/:IsForProperty/:IsForDataset
    getDatasetMappingParameters: (state, action) => {
      state.mappingFileParameters.ServiceProviderId =
        action.payload.ServiceProviderId;
      state.mappingFileParameters.FundId = action.payload.FundId;
      state.mappingFileParameters.PropertyId = action.payload.PropertyId;
      state.mappingFileParameters.DatasetOrReportPackageId =
        action.payload.DatasetOrReportPackageId;
      state.mappingFileParameters.IsForProperty = action.payload.IsForProperty;
      state.mappingFileParameters.IsForDataset = action.payload.IsForDataset;
    },

    addDatasetMappingParameters: (state, action) => {
      if (action.payload) {
        handleMappingData(state, action.payload);
      }
    },
    removeDatasetMappingParameters: (state, action) => {
      if (action.payload === 0) {
        state.mappingFileParam = [];
      }
    },
    viewMappingflagParameters: (state, action) => {
      if (action.payload === 1) {
        state.viewMappingflag=true
      } else {
        state.viewMappingflag=false
      }
    },
    handleFundEnable: (state, action) => {
      state.isFundEnabled = action.payload;
    },
  },
});

export const {
  //   getDataset,
  //   getReportPackageDataset,
  //   addReviewerToTable,
  //   addReviewer,
  getDatasetMappingParameters,
  addDatasetMappingParameters,
  removeDatasetMappingParameters,
  viewMappingflagParameters,
  handleFundEnable
} = DatasetMappingSlice.actions;

export default DatasetMappingSlice.reducer;
