//import axios from 'axios';
import axios from '../configs/axiosConfig'
import appConfig from '../configs/appConfig';
const NOTIFICATION_API_BASE_URL = appConfig.API_BASE_URL + "email/history/";
const EMAIL_TEMPLATE_API_BASE_URL = appConfig.API_BASE_URL + "email/";

class NotificationService {

    getEmailHistoryNotRead(loginUserId, isForCount) {
        return axios.get(NOTIFICATION_API_BASE_URL + `notread?IsForCount=${isForCount}&LoginUserId=${loginUserId}`);
    }

    getEmailTemplate(templateName) {
        return axios.get(EMAIL_TEMPLATE_API_BASE_URL + `template?TemplateName=${templateName}`);
    }

    updateEmailHistoryIsRead(EmailHistoryId, loginUserId) {
        return axios.put(NOTIFICATION_API_BASE_URL + "isread", { EmailHistoryId, loginUserId });
    }

    addEmailHistory() {
        return axios.post(NOTIFICATION_API_BASE_URL + "add");
    }
}

export default new NotificationService();