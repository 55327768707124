import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    // Retrieve the object from the storage
    const data =
      localStorage.getItem("userData");
    // console.log("Sidebar - data: ", JSON.parse(data));
    const userData = JSON.parse(data);

    if (!userData) {
      // If userData is not present, redirect to the login page
      window.location.href = "/";

    }

    // console.log("userName",userData.userName);
    // console.log("userRole",userData.userRole);

    // this.state = {
    //   activeItem: ""
    // };

    this.state = {
      userName: userData.userName,
      userRole: userData.userRole,
      userEmail: userData.userEmail,
      userId: userData.userId,
      activeItem: ""
    };

    this.checkState = this.checkState.bind(this);

  }

  checkState(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    //console.log (this.state);
  }

  componentDidMount() {
    // Get the current pathname when the component mounts and set the activeItem state
    this.setState({
      activeItem: window.location.pathname
    });
  }

  handleItemClick = (url) => {
    this.setState({ activeItem: url });
  };

  render() {
    const { userName, userRole, userEmail } = this.state;
    const { t } = this.props;

    return (

      <div className="d-flex">
        <aside class="main-sidebar sidebar-dark-primary elevation-4 leftMenu">
          <div class="sidebar" id="sidebar">
            <div class="row">

              <div class="col-md-12 ">
                <a href="https://www.4seeanalytics.com/dev_new" class="leftnavlink"><i class="fas fa-arrow-left me-2"></i> Back Home</a>
              </div>
            </div>
            <nav class="sidebar py-2 mb-4 scrollbar" id="navscrollbar">
              {/* <ul>
          <li><a className="d-flex active" href="/dashboard"><i><img src="../assets/images/icon-dashboard.svg"/></i><span>Dashboard</span></a></li>
          <li><a className="d-flex" href="/funds"><i><img src="../assets/images/icon-fund.svg"/></i><span>Funds</span></a></li>
          <li><a className="d-flex" href="properties.html"><i><img src="../assets/images/icon-property.svg"/></i><span>Properties</span></a></li>
          <li><a className="d-flex" href="service_providers.html"><i><img src="../assets/images/icon-serviceProvider.svg"/></i><span>Service Providers</span></a></li>
          <li><a className="d-flex" href="All_Users.html"><i><img src="../assets/images/icon-users.svg"/></i><span>Users</span></a></li>
          <li><a className="d-flex" href="roles.html"><i><img src="../assets/images/icon-userRoles.svg"/></i><span>Roles</span></a></li>
                    <li><a className="d-flex submenu collapsed" data-bs-toggle="collapse" href="#submenu5" role="button" aria-expanded="false" aria-controls="submenu5"><i><img src="../assets/images/icon-COA.svg"/></i><span>Chart Of Accounts</span></a>
            <ul className="collapse" id="submenu5">
              <li><a className="d-flex" href="chartsofAC.html"><span>Master COA</span></a></li>
              <li><a className="d-flex" href="COA-mapping-new.html"><span>COA Mapping</span></a></li>
            </ul>
          </li>
         
          <li><a className="d-flex submenu collapsed" data-bs-toggle="collapse" href="#submenu2" role="button" aria-expanded="false" aria-controls="submenu2"><i><img src="../assets/images/icon-setup.svg"/></i><span>Setup</span></a>
            <ul className="collapse" id="submenu2">
              <li><a className="d-flex" href="mapping-new.html"><span>Mapping</span></a></li>
              <li><a className="d-flex" href="rulesSet.html"><span>Rules Engine</span></a></li>
              <li><a className="d-flex" href="Upload_Frequency.html"><span>Manage Frequency</span></a></li>
              <li><a className="d-flex" href="Uploaded_Files.html"><span>View Uploaded Files</span></a></li>
              <li><a className="d-flex" href="Integrations_Center.html"><span>Integrations Center</span></a></li>
            </ul>
          </li>
        </ul> */}


              {(userRole === "Admin" || userRole === "SuperAdmin") && <ul class="nav flex-column">

                <li className="nav-item"><Link onClick={() => this.handleItemClick('/dashboard')} className={this.state.activeItem === "/dashboard" ? "nav-link active" : "nav-link"} to="/dashboard"><span>{t("Sidebar.Dashboard")}</span></Link></li>


                <li className="nav-item"><Link onClick={() => this.handleItemClick('/funds')} className={this.state.activeItem === "/funds" ? "nav-link active" : "nav-link"} to="/funds"><span>{t("Sidebar.Funds")}</span></Link></li>
                <li onClick={() => this.handleItemClick('/properties')} className={this.state.activeItem === "/properties" ? "nav-item active" : "nav-item"}><Link className={this.state.activeItem === "/properties" ? "nav-link active" : "nav-link"} to="/properties"><span>{t("Sidebar.Properties")}</span></Link></li>
                <li onClick={() => this.handleItemClick('/serviceProviders')} className={this.state.activeItem === "/serviceProviders" ? "nav-item active" : "nav-item"}><Link className={this.state.activeItem === "/serviceProviders" ? "nav-link active" : "nav-link"} to="/serviceProviders"><span>{t("Sidebar.Service_Providers")}</span></Link></li>
                <li onClick={() => this.handleItemClick('/viewuploadedfiles')} className={this.state.activeItem === "/viewuploadedfiles" ? "nav-item active" : "nav-item"}><Link className={this.state.activeItem === "/viewuploadedfiles" ? "nav-link active" : "nav-link"} to="/viewuploadedfiles"><span>{t("Sidebar.View_Uploaded_Files")}</span></Link></li>

                <li className="nav-item has-submenu current"><Link className="nav-link submenu collapsed" data-bs-toggle="collapse" to="#submenu2" role="button" aria-expanded="false" aria-controls="submenu2"><span>{t("Sidebar.Setup")}</span></Link>
                  <ul className="collapse submenu" id="submenu2">

                    <li className="nav-item has-submenu"><Link className="nav-link submenu collapsed" data-bs-toggle="collapse" to="#submenu6" role="button" aria-expanded="false" aria-controls="submenu6"><span>{t("Sidebar.Users")}</span></Link>
                      <ul className="collapse submenu" id="submenu6">
                        <li className="nav-item"><Link onClick={() => this.handleItemClick('/alluser')} className={this.state.activeItem === "/alluser" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/alluser"><span>{t("Sidebar.Users_List")}</span></Link></li>
                        <li className="nav-item" style={{ display: userRole === "SuperAdmin" ? 'block' : 'none' }}><Link onClick={() => this.handleItemClick('/roles')} className={this.state.activeItem === "/roles" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/roles"><span>{t("Sidebar.Roles")}</span></Link></li>
                      </ul>
                    </li>

                    <li className="nav-item"><Link onClick={() => this.handleItemClick('/property_mapping')} className={this.state.activeItem === "/property_mapping" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/property_mapping"><span>{t("Sidebar.Property_Mapping")}</span></Link></li>

                    <li className="nav-item has-submenu"><Link className="nav-link submenu collapsed" data-bs-toggle="collapse" to="#submenu5" role="button" aria-expanded="false" aria-controls="submenu5"><span>{t("Sidebar.Chart_Of_Accounts")}</span></Link>
                      <ul className="collapse submenu" id="submenu5">
                        <li className="nav-item"><Link onClick={() => this.handleItemClick('/chartsofAC')} className={this.state.activeItem === "/chartsofAC" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/chartsofAC"><span>{t("Sidebar.Master_COA")}</span></Link></li>
                        <li className="nav-item"><Link onClick={() => this.handleItemClick('/COA-mapping-new')} className={this.state.activeItem === "/COA-mapping-new" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/COA-mapping-new"><span>{t("Sidebar.COA_Mapping")}</span></Link></li>
                      </ul>
                    </li>

                    <li className="nav-item"><Link onClick={() => this.handleItemClick('/dataset')} className={this.state.activeItem === "/dataset" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/dataset"><span>{t("Sidebar.Dataset")}</span></Link></li>

                    <li className="nav-item"><Link onClick={() => this.handleItemClick('/configureWorkflow')} className={this.state.activeItem === "/configureWorkflow" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/configureWorkflow"><span>{t("Sidebar.Configure_Workflow")}</span></Link></li>


                    <li className="nav-item"><Link onClick={() => this.handleItemClick('/mapping')} className={this.state.activeItem === "/mapping" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/mapping"><span>{t("Sidebar.Mapping")}</span></Link></li>
                    <li className="nav-item"><Link onClick={() => this.handleItemClick('/rules_engine')} className={this.state.activeItem === "/rules_engine" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/rules_engine"><span>{t("Sidebar.Rules_Engine")}</span></Link></li>
                    <li className="nav-item"><Link onClick={() => this.handleItemClick('/managefrequency')} className={this.state.activeItem === "/managefrequency" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/managefrequency"><span>{t("Sidebar.Manage_Frequency")}</span></Link></li>

                    <li className="nav-item"><Link onClick={() => this.handleItemClick('/integrationCenter')} className={this.state.activeItem === "/integrationCenter" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/integrationCenter"><span>{t("Sidebar.Integration_Center")}</span></Link></li>

                  </ul>
                </li>

                <li className="nav-item has-submenu current" style={{ display: userRole === "SuperAdmin" ? 'block' : 'none' }}
                ><Link className="nav-link submenu collapsed" data-bs-toggle="collapse" to="#submenu7" role="button" aria-expanded="false" aria-controls="submenu5"><span>{t("Sidebar.System_Master")}</span></Link>
                  <ul className="collapse submenu" id="submenu7">
                    <li className="nav-item"><Link onClick={() => this.handleItemClick('/lookup_key')} className={this.state.activeItem === "/lookup_key" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/lookup_key"><span>{t("Sidebar.Lookup_Key")}</span></Link></li>
                    <li className="nav-item"><Link onClick={() => this.handleItemClick('/lookup_value')} className={this.state.activeItem === "/lookup_value" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/lookup_value"><span>{t("Sidebar.Lookup_Value")}</span></Link></li>
                    <li className="nav-item"><Link onClick={() => this.handleItemClick('/glossary')} className={this.state.activeItem === "/glossary" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/glossary"><span>Glossary</span></Link></li>
                  </ul>
                </li>

                <li className="nav-item has-submenu current"><Link className="nav-link submenu collapsed" data-bs-toggle="collapse" to="#submenu11" role="button" aria-expanded="false" aria-controls="submenu5"><span>{t("Sidebar.Reports")}</span></Link>
                  <ul className="collapse submenu" id="submenu11">
                    <li className="nav-item"><Link onClick={() => this.handleItemClick('/report')} className={this.state.activeItem === "/report" ? "nav-link text-truncate active" : "nav-link text-truncate"} to="/report"><span>{t("Sidebar.BIReports")}</span></Link></li>
                  </ul>
                </li>
              </ul>}

              {userRole == "DataProvider" && <ul class="nav flex-column">
                <li className="nav-item"><Link class="nav-link active" to="/serviceprovider-dashboard"><span>{t("Sidebar.Dashboard")}</span></Link></li>

                <li className="nav-item has-submenu current"><Link class="nav-link submenu collapsed" data-bs-toggle="collapse" to="#submenu5" role="button" aria-expanded="false" aria-controls="submenu5"><span>{t("Sidebar.Setup")}</span></Link>
                  <ul class="collapse submenu" id="submenu5">
                    <li className="nav-item has-submenu current"><Link class="nav-link submenu collapsed" data-bs-toggle="collapse" to="#submenu6" role="button" aria-expanded="false" aria-controls="submenu5"><span>{t("Sidebar.Chart_Of_Accounts")}</span></Link>
                      <ul class="collapse submenu" id="submenu6">
                        {/* <li><Link className={this.state.activeItem === "/chartsofAC" ? "nav-link active" : "nav-link"} to="/chartsofAC"><span>Master COA</span></Link></li> */}
                        <li className="nav-item"><Link className={this.state.activeItem === "/COA-mapping-new" ? "nav-link text-truncate active" : "nav-link text-truncate "} to="/COA-mapping-new"><span>{t("Sidebar.COA_Mapping")}</span></Link></li>
                      </ul>
                    </li>

                  </ul>
                </li>

              </ul>
              }


              {userRole == "Reviewer" && <ul class="nav flex-column">
                <li className="nav-item"><Link className={this.state.activeItem === "/reviewer-dashboard" ? "nav-link active" : "nav-link"} to="/reviewer-dashboard"><span>{t("Sidebar.Dashboard")}</span></Link></li>
              </ul>
              }



            </nav>
          </div>
        </aside>


        {/* <main>{this.props.children}</main> */}
        {this.props.children ? this.props.children : <Outlet />}

      </div>

    );
  }
}
export default withTranslation()(Sidebar);