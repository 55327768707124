//import axios from "axios";
import axios from '../configs/axiosConfig'
import { AppConfig } from "../configs/appConfig";

const COMMON_API_BASE_URL = AppConfig.API_BASE_URL + "common";

class CommonModuleService {

    getCountry() {
        const COUNTRY_API_BASE_URL = COMMON_API_BASE_URL + "/country";
        return axios.get(COUNTRY_API_BASE_URL);
    }

    getState(CountryId) {
        const STATE_API_BASE_URL = COMMON_API_BASE_URL + "/state?CountryId=" + CountryId;
        return axios.get(STATE_API_BASE_URL);
    }

    getLookupType() {
        const GET_LOOKUP_TYPE = COMMON_API_BASE_URL + "/lookuptype";
        return axios.get(GET_LOOKUP_TYPE);
    }
    getLookupValues(LookupTypeId) {
        const GET_LOOKUP_VALUES = COMMON_API_BASE_URL + "/lookupvalues?LookupTypeId=" + LookupTypeId;
        return axios.get(GET_LOOKUP_VALUES);
    }

    getLookupTypeValueByName(lookupTypeName) {
        return axios.get(COMMON_API_BASE_URL + "/lookUpvaluebylookuptype",
            {
                params: {
                    LookupTypeName: lookupTypeName
                }
            })
    }

    addLookupValues(addFundType) {
        const POST_LOOKUP_VALUES = COMMON_API_BASE_URL + "/lookupvalues";
        return axios.post(POST_LOOKUP_VALUES, addFundType);
    }

    getLookupCustomValuesByOperationId(LookupValueId) {
        const GET_LOOKUP_VALUES = COMMON_API_BASE_URL + "/customvalue/" + LookupValueId;
        return axios.get(GET_LOOKUP_VALUES);
    }

    downloadTemplateFile(templateName) {
        // const response = axios.post(AppConfig.API_BASE_URL + "blob/download/importfile/template", {
        //     params: { templatename: templateName },
        //     responseType: 'arraybuffer',
        // });

        const response = axios.get(AppConfig.API_BASE_URL + "blob/download/importfile/template", {
            params: { templatename: templateName },
            responseType: "arraybuffer",
          });

        return response;
    }

    getFileBytesByBlobUrl(bloburl) {
        const response = axios.get(AppConfig.API_BASE_URL + "blob/blobfilebyurl", {
            params: { bloburl: bloburl },
            responseType: 'arraybuffer',
        });

        return response;
    }

    getLanguage() {
        const LANGUAGE_API_BASE_URL = COMMON_API_BASE_URL + "/language";
        return axios.get(LANGUAGE_API_BASE_URL);
    }
}

export default new CommonModuleService();