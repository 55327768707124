import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  propertyRowData: [],
  propertyDataset: [],
  masterCOAAccountInboxData: [],
  masterCoaProperty: [],
  masterCoaFund: [],
  IsForProperty: 1, // Initial value set to 1
  editedPropertyDataset: [],
  selectedMasterCOA: 0,
};

const updateEditedPropertyDataset = (state, action) => {
  const existingDataIndex = state.editedPropertyDataset.findIndex(
    (data) => data.MappingId === action.MappingId
  );

  if (existingDataIndex !== -1) {
    state.editedPropertyDataset[existingDataIndex] = action;
  } else {
    state.editedPropertyDataset.push(action);
  }
};

export const coaSlice = createSlice({
  name: "coa",
  initialState,
  reducers: {
    getPropertyRowData: (state, action) => {
      state.propertyRowData = action.payload;
    },
    getPropertyDataset: (state, action) => {
      state.propertyDataset = action.payload;
    },
    getMasterCOAAccountInboxData: (state, action) => {
      state.masterCOAAccountInboxData = action.payload;
    },
    addMasterCoaProperty: (state, action) => {
      state.masterCoaProperty = action.payload;
    },
    addMasterCoaFund: (state, action) => {
      state.masterCoaFund = action.payload;
    },
    updateIsForProperty: (state, action) => {
      state.IsForProperty = action.payload;
    },
    updateSelectedMasterCOA: (state, action) => {
      state.selectedMasterCOA = action.payload;
    },
    addEditedPropertyDataset: (state, action) => {
      if (action.payload === 0) {
        state.editedPropertyDataset = [];
      } else if (state.editedPropertyDataset.length === 0) {
        state.editedPropertyDataset.push(action.payload);
      } else {
        updateEditedPropertyDataset(state, action.payload);
      }
    },
  },
});

export const {
  getPropertyDataset,
  getMasterCOAAccountInboxData,
  getPropertyRowData,
  addMasterCoaProperty,
  addMasterCoaFund,
  updateIsForProperty,
  addEditedPropertyDataset,
  updateSelectedMasterCOA
} = coaSlice.actions;

export default coaSlice.reducer;
