import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
  selectedMenu: [],
  propertyInbox: [],
  filteredPropertyInbox: [], // New state property
};

const updateInitialFrequencyData = (state, action) => {
  // Create a copy of the row object to avoid mutating the original state
  const updatedRow = { ...action.row };
  // Update the property on the copied row object
  updatedRow.SubmitFrequency = action.value;
  // Return a new state object with the updated row
  return {
    ...state,
    value: [...state.value, updatedRow]
  };
};

const updateFrequencyData = (state, action) => {
  const existingDataIndex = state.value.findIndex(
    (data) => data.MappingFileId === action.row.MappingFileId
  );

  if (existingDataIndex !== -1) {
    state.value[existingDataIndex].SubmitFrequency = action.value;
  } else {
    state.value.push(action.row);
  }
};

const updateInitialTimelineData = (state, action) => {
  console.log(action.payload , 'action')
  action.row.SubmitTimelineDay = action.value;
  state.value.push(action.row);
};

const updateTimelineData = (state, action) => {
  console.log(action , 'updateTimelineData')
  const existingDataIndex = state.value.findIndex(
    (data) => data.MappingFileId === action.row.MappingFileId
  );

  if (existingDataIndex !== -1) {
    state.value[existingDataIndex].SubmitTimelineDay = action.value;
  } else {
    state.value.push(action.row);
  }
};

export const frequencyEditRowSlice = createSlice({
  name: "frequecy-row-edit",
  initialState,
  reducers: {
    editfrequency: (state, action) => {
      state.value.length === 0
        ? updateInitialFrequencyData(state, action.payload)
        : updateFrequencyData(state, action.payload);
    },
    editTimline: (state, action) => {
      console.log(state.value)
      state.value.length === 0
        ? updateInitialTimelineData(state, action.payload)
        : updateTimelineData(state, action.payload);
    },
    addSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload;
    },
    setInitialPropertyInbox: (state, action) => {
      state.propertyInbox = action.payload;
    },
    setFilteredPropertyInbox: (state, action) => {
      state.filteredPropertyInbox = action.payload;
    },
  },
});

export const { editfrequency, editTimline, addSelectedMenu, setInitialPropertyInbox ,setFilteredPropertyInbox} =
  frequencyEditRowSlice.actions;

export default frequencyEditRowSlice.reducer;
