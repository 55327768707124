import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function ImpersonateModal({ show, handleClose, allUsersData }) {
  const [selectedUser, setSelectedUser] = useState([]);
  const navigate = useNavigate();

  const handleChange = async (id) => {
    const getSelectedUser = allUsersData.filter((user) => user.UserId === id);
    getSelectedUser && setSelectedUser(getSelectedUser);
  };

  const handleSubmit = () => {
    const newObj = selectedUser.length > 0 && {
      userEmail: selectedUser[0].Email,
      userId: selectedUser[0].UserId,
      userName: selectedUser[0].UserName,
      userRole: selectedUser[0].RoleName,
      userShortName: selectedUser[0].FirstName,
    };
    const userData = localStorage.getItem("userData");

    localStorage.setItem("userImpersonateData", userData);
    localStorage.setItem("userData", JSON.stringify(newObj));

    let url = "";
    if (newObj.userRole === "Admin") {
      url = "/dashboard";
    } else if (newObj.userRole === "DataProvider") {
      url = "/serviceprovider-dashboard";
    } else if (newObj.userRole === "Reviewer") {
      url = "/reviewer-dashboard";
    }

    if (url) {
      navigate(url);
    }
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" backdrop={"static"}>
      <Modal.Header closeButton>
        <Modal.Title>Impersonate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div class="row">
            <div class="col-12 mb-3 fund2 multiSelect multiSelect4">
              <label for="sataset" class="col-form-label">
                Select User
              </label>
              <select
                name="serviceProvider"
                id="serviceProvider"
                className="form-select"
                onChange={(e) => handleChange(e.target.value)}
              >
                {allUsersData.length > 0 &&
                  allUsersData.map((user) => (
                    <option value={user.UserId}>{user.UserName}</option>
                  ))}
              </select>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => handleSubmit()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImpersonateModal;
