
import { Outlet , useRoutes , Routes , Route} from 'react-router-dom';
import Themeroutes from './routes/Router';

import logo from './logo.svg';
import './App.css';
import Login from './pages/login/login';
import AccessDenied from './pages/error/accessDenied';
import 'toastr/build/toastr.min.css';
//import './styles/style.css';

function App({instance}) {
  const routing = useRoutes(Themeroutes)
  if(window.location.pathname !== '/'&& window.location.pathname !== '/unauthorizedaccess' && window.location.search){
      var url = window.location
      sessionStorage.setItem('redirectUrl' , JSON.stringify(url))
  }else {
    url = JSON.parse(sessionStorage.getItem('redirectUrl'))
  }
   
  return (
    <>
   {/* <Outlet /> */}
   <Routes>
   <Route path='/' index element={<Login instance={instance} url={url}/>} />  
   <Route path='/unauthorizedaccess' element={<AccessDenied/>}/>
   </Routes>
   {routing}
   </>
  );
}

export default App;
