import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication,EventType } from '@azure/msal-browser';
import {msalConfig} from './configs/msalConfig';
import { MsalProvider } from '@azure/msal-react';

import './index.css';
import './assets/bootstrap/css/bootstrap.min.css'; 
// import './assets/bootstrap/css/bootstrap.rtl.min.css';
import './assets/css/style.css';
import './assets/css/style_dap.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { store } from './store/store';

import './i18n';
//import './styles/style.css';

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();


await msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
});

const root = ReactDOM.createRoot(document.getElementById('root'));
  
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <Provider store={store}>
      <BrowserRouter>
      <MsalProvider instance={msalInstance}>
      <App instance={msalInstance}/>
      </MsalProvider>
      </BrowserRouter>
    </Provider>
  
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
